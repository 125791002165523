import React from "react";
// import { BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
// import Projects from "./pages/projects";
// import Experience from "./pages/experience";
// // import Skills from "./pages/skills";
// import NotFound from "./pages/NotFound";
// import { NavLink } from "react-router-dom";
import Lottie from "lottie-react";
import web from "./assets/web.json";
import { useRef } from "react";


import { rainbowCursor } from "cursor-effects";
new rainbowCursor({ colors: ["#FFFFFF"] });

function App() {
  const LottieAnimation = useRef();
  return (
    <div>
    <div className="flex justify-end opacity-5 mx-auto absolute">
          <Lottie
            onComplete={() => {
              LottieAnimation.current?.setSpeed(0.1);
            }}
            lottieRef={LottieAnimation}
            animationData={web}
            loop={true}
            height={50}
          />
        </div>
        
        <div className="px-8 mx-auto xs:w-full py-24 md:py-36 absolute z-30">
          <Home />
          </div>
         
    </div>
    // <>
    //   <BrowserRouter>
    //     {/* <nav className="">
    //       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
    //         <div className="flex items-center justify-between h-16">
    //           <div className="flex items-center">
    //             <div className="block">
    //               <div className="ml-10 flex items-baseline space-x-4">
    //                 <NavLink
    //                   to="/"
    //                   className="text-gray-300 hover:text-white hover:border hover:border-white px-3 py-1 rounded text-sm font-bold plainBlack" activeClassName="active" exact
    //                 >
    //                   Home
    //                 </NavLink>
    //                 <NavLink
    //                   to="/rey/experience"
    //                   className="text-gray-300 hover:text-white hover:border hover:border-white px-3 py-1 rounded text-sm font-bold plainBlack" activeClassName="active"
    //                 >
    //                   Edu & Exp
    //                 </NavLink>
    //                 <NavLink
    //                   to="/rey/projects"
    //                   className="text-gray-300 hover:text-white hover:border hover:border-white px-3 py-1 rounded text-sm font-bold plainBlack" activeClassName="active"
    //                 >
    //                   Projects
    //                 </NavLink>
                    
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </nav> */}
    //     {/* <div className="flex justify-end opacity-5 mx-auto absolute ">
    //       <Lottie
    //         onComplete={() => {
    //           LottieAnimation.current?.setSpeed(0.1);
    //         }}
    //         lottieRef={LottieAnimation}
    //         animationData={web}
    //         loop={false}
    //         height={100}
    //       />
    //     </div>
        
    //     <div className="px-8 md:p-0 container mx-auto xs:w-full md:w-1/3 my-20">
    //       <Home />
    //         {/* <Routes>
    //       <Route path="/" exact element={<Home />} />
    //       <Route path="/rey/projects" element={<Projects />} />
    //       <Route path="/rey/experience" element={<Experience />} />
    //       <Route path="*" element={<NotFound />}></Route>
    //     </Routes> */}
    //       </div> */}
         
      
    //   </BrowserRouter>
    //   {/* <div className="w-1/2 mx-auto md:w-1/4 h-[280px] overflow-hidden opacity-60">
    //     <Lottie
    //       onComplete={() => {
    //         LottieAnimation.current?.setSpeed(0.5);
    //       }}
    //       lottieRef={LottieAnimation}
    //       animationData={codeNight}
    //       loop={true}
    //     />
    //   </div> */}
    //   <div className="bg-[#333] w-full text-center py-4 text-xs md:text-sm">josephreytolentino@gmail.com</div>
    // </>
  );
}

export default App;
