import React from "react";
// import { useRef } from "react";
// import Lottie from "lottie-react";
import { MotionAnimate } from "react-motion-animate";
// import codeNight from "../assets/codeNight.json";
import me from "../assets/images/pinal2.png";

const Skills = () => {
  // const LottieAnimation = useRef();

  const webDeveloperSkills = [
    { skill: "HTML", percentage: 100 },
    { skill: "CSS", percentage: 100 },
    { skill: "JavaScript", percentage: 95 },
    { skill: "Bootstrap", percentage: 100 },
    { skill: "Tailwind CSS", percentage: 100 },
    { skill: "Vue", percentage: 95 },
    { skill: "React", percentage: 80 },
    { skill: "Laravel", percentage: 80 },
    { skill: "SQL", percentage: 85 },
    { skill: "MySQL", percentage: 80 },
    { skill: "XANO", percentage: 80 },
    { skill: "NPM Integrations", percentage: 100 },
    { skill: "API Integrations", percentage: 90 },
    { skill: "Git", percentage: 90 },
    { skill: "Responsive Design", percentage: 95 },
    { skill: "Version Control", percentage: 95 },
    { skill: "Agile Development", percentage: 100 },
  ];

  return (
    <div className="mt-12">
      {/* <h1 className="text-2xl font-bold mb-4">Skills</h1> */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-0  md:gap-4">
        <div className="h-full grid place-items-stretch">
          <MotionAnimate reset={false} distance={200} speed={3}>
            <div>
              Driven by a relentless thirst for knowledge, I actively engage in
              professional development through workshops, technical reading, and
              personal coding projects.
              <br />
              <br />
              I constantly refine my skillset by mastering existing tools and
              exploring new languages. Collaborative learning, seeking feedback,
              and mentorship are cornerstones of my growth.
              <br />
              <br />
              This unwavering commitment to continuous improvement ensures I stay
              ahead of the curve and evolve as a software engineer.
              <br />
              <br />
            </div>
          </MotionAnimate>

          <img src={me} alt="tr" id="mee" border="0" className="w-full h-full invert pt-16 hover:invert-0 active:invert" width={100} height={100} />
        </div>

        <MotionAnimate
          reset={false}
          distance={200}

        >
          <div className="flex flex-wrap mt-8 md:mt-0">
            {webDeveloperSkills.map((skill, index) => (
              <div
                key={index}
                className="bg-[#333] text-white rounded-full px-4 py-2 m-2 hover:scale-150 transition-transform duration-200 ease-in-out"
                style={{
                  width: `${skill.percentage}%`,
                  border: "1px solid white", // Add this line to add a border
                }}
              >
                <div className="flex justify-between w-full">
                  <span>{skill.skill}</span>{" "}
                  <span className="grid place-items-center">
                    {skill.percentage}%
                  </span>
                </div>
              </div>
            ))}
          </div>
        </MotionAnimate>
      </div>
    </div>
  );
};

export default Skills;
