import React from 'react';
import working from '../assets/working.png'
import graduationHat from '../assets/graduation-hat.png'

import ahg from '../assets/companies/ahg.png'
import th from '../assets/companies/th.png'
import favicon from '../assets/companies/favicon.ico'
import mycure from '../assets/companies/mycure2.png'
import { MotionAnimate } from "react-motion-animate";
// import ldsTools from '../assets/companies/lds-tools.png'
import camanco from '../assets/companies/camanco.png' 
import slers from '../assets/companies/slers.png'
import cvsu from '../assets/companies/cvsu.png'
import nbsc from '../assets/companies/nbsc.png'
import mati from '../assets/companies/mati.png'
import bbi from '../assets/companies/Wordmark_BBI-Consultants.jpg'
import sandlot from '../assets/companies/sandlot.png'
import NewLdsLogo from '../assets/companies/newLdsLogo.jpg'

const Experienece = () => {
  const experiences = [
    {
      title: 'Software Engineer',
      company: 'Sandlot',
      logo:sandlot,
      duration: '2024 January - 2024 September',
      link:'https://www.sandlot.ph/',
      color:'333'
    },
    {
      title: 'Junior Software Engineer',
      company: 'Talent Hero',
      logo:th,
      duration: '2023 March  - 2024 January',
      link:'https://talenthero.co/',
      color:'333'
    },
    {
      title: 'FreeLance Web Developer',
      company: 'BBI Consults',
      logo:bbi,
      duration: '2023 December - 2024 March ',
      link:'https://www.bbiconsults.com/',
      color:'333'
    },
    {
      title: 'Vue js Developer',
      company: 'AHG lab',
      logo:ahg,
      duration: '2022 September - 2023 March',
      link:'https://www.ahglab.com/',
      color:'333'
    },
    {
      title: 'Freelance Web Developer',
      company: 'Freelance',
      logo:favicon,
      duration: '2019 June - 2022 August',
      link:'',
      color:'333'
    },
    {
      title: 'Web Developer Intern',
      company: 'MYCURE',
      logo:mycure,
      duration: '2021 May - 2021 July',
      link:'https://www.mycure.md/',
      color:'333'
    },
    {
      title: 'Office Clerk (Volunteer)',
      company: 'The Church of Jesus Christ of Latter Day Saints(LDS)',
      logo:NewLdsLogo,
      duration: '2018 September - Present',
      link:'https://www.churchofjesuschrist.org/?lang=eng',
      color:'333'
    },
    {
      title: 'Machine Operator',
      company: 'Cavite Manufacturing Company (Camanco) Inc.',
      logo:camanco,
      duration: '2018 April - 2018 August',
      link:'https://company-report.crif.com.ph/en/company/ph-PH0000014276/cavite-manufacturing-company-inc/',
      color:'333'
    },
    {
      title: 'Sanitation Officer',
      company: 'SLERS Industries, Inc.',
      logo:slers,
      duration: '2017 March - 2017 July',
      link:'http://www.slers.com/',
      color:'333'
    },
  ];

  const education = [
    {
      degree: 'BS in Information Technology (Graduate)',
      university: 'Cavite State University - Cavite City Campus',
      logo:cvsu,
      duration: 'June 2018 - June 2023',
      link:'https://cvsu.edu.ph/',
      color:'47A051'
    },
    {
      degree: 'BS in Information Technology (Undergraduate)',
      university: 'Northern Bukidnon State College',
      logo:nbsc,
      duration: 'June 2017 - May 2018',
      link:'https://nbsc.edu.ph/',
      color:'00235C'
    },
    {
      degree: 'BS in Business Administration (Office Management, undergraduate)',
      university: 'Mindanao Arts and Technological Institute',
      logo:mati,
      duration: 'August 2012 - January 2013',
      link:'https://www.facebook.com/people/MATI-Mindanao-Arts-and-Technological-Institute/100064785904007/',
      color:'FAF925'
    },
  ];

  
  return (
    <div>
       <hr className="my-24 opacity-30"/>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 my-12">
      <div>
      <MotionAnimate animation='fadeInUp'
                        reset={false}
                        distance={200}
                        speed={2}>
        <h1 className="text-2xl font-bold mb-12 flex gap-4 h-12">
          
          <img src={working} alt="working icon" className="inline-block webb bg-gray-200 p-2 rounded-xl border-2 border-gray-400"/>
          <div className='grid place-items-center'>Experience</div>
        </h1>
      <ul className="grid grid-cols-1 gap-4">
        {experiences.map((experience, index) => (
          <li
            key={index}
            className={`bg-gradient-to-br from-[#333] via-[#333] to-[#${experience.color}] text-white shadow-md rounded p-4 border border-gray-500 hover:scale-110 transition-transform duration-200 ease-in-out`}
          >
       
            <h2 className="text-lg font-bold mb-4">{experience.title}</h2>
            <a href={experience.link} target='blank_' className="text-gray-300 mb-1 hover:text-blue-400 font-bold active:text-red-400 flex flex-wrap gap-2">
           <div className='w-full flex gap-3'>
            <div className='grid place-items-center'>
              {experience.company === 'Sandlot' ||  experience.company === 'The Church of Jesus Christ of Latter Day Saints(LDS)' ? <img src={experience.logo} alt="logo" className='w-[100px] rounded-md' /> : <img src={experience.logo} alt="logo" className='w-[50px] rounded-full' />}
            {/* <img src={experience.logo} alt="logo" className='w-[40px] rounded-full' /> */}
            </div>
            <div className='flex items-center'>
              <div>
<div>
{experience.company}
</div>
 <p className="text-gray-400 text-sm">{experience.duration}</p>
              </div>

            </div>
            
            </div></a>
           
          </li>
        ))}
      </ul>
      </MotionAnimate>
      </div>
  <div>
  <MotionAnimate animation='fadeInUp'
                        reset={false}
                        distance={200}
                        speed={1}>
     <h1 className="text-2xl font-bold mb-12 flex gap-4 h-12">
     
     <img src={graduationHat} alt="education icon" className="inline-block webb bg-gray-200 p-1 rounded-xl border-2 border-gray-400"/>
     <div className='grid place-items-center'>Education</div>
     </h1>
      <ul className="grid grid-cols-1 gap-4">
        {education.map((edu, index) => (
          <li
            key={index}
            className={`bg-gradient-to-br from-[#333] via-[#333] ${edu.color ? `to-[#${edu.color}]` : ''}  text-white shadow-md rounded p-4 border border-gray-500 hover:scale-110 transition-transform duration-200 ease-in-out`}
          >
            <h2 className="text-lg font-bold mb-4">{edu.degree}</h2>
            <a href={edu.link} target='blank_' className="text-gray-300 mb-1 hover:text-blue-400 font-bold active:text-red-400 flex flex-wrap gap-2">
            <div className='w-full flex gap-3'>
            <div className='grid place-items-center'>
            <img src={edu.logo} alt="logo" className='w-[50px] rounded-full' />
            </div>
            <div className='flex items-center'>
              <div>
                <div>
                  {edu.university}
                </div>
                 <p className="text-gray-400 text-sm">{edu.duration}</p>  
              </div>

            </div>
            </div>
           </a>
           
          </li>
          
        ))}
      </ul>
      </MotionAnimate>
  </div>
    </div>
    </div>
   
  );
}

export default Experienece;
